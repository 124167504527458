@font-face {
font-family: '__PeydaFont_3797c5';
src: url(/_next/static/media/878838b8931a67a2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: '__PeydaFont_3797c5';
src: url(/_next/static/media/24fae7011a7337a3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__PeydaFont_3797c5';
src: url(/_next/static/media/2a5718410b5cf129-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__PeydaFont_3797c5';
src: url(/_next/static/media/2e47d0615f405746-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__PeydaFont_3797c5';
src: url(/_next/static/media/e0373018c89c9a06-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__PeydaFont_3797c5';
src: url(/_next/static/media/67a1d1d266ed13d1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__PeydaFont_3797c5';
src: url(/_next/static/media/2ce1edd91487efd1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__PeydaFont_3797c5';
src: url(/_next/static/media/25a2b9e93551f313-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__PeydaFont_3797c5';
src: url(/_next/static/media/e24718c75c3ca7ff-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__PeydaFont_Fallback_3797c5';src: local("Arial");ascent-override: 90.33%;descent-override: 43.12%;line-gap-override: 0.00%;size-adjust: 92.77%
}.__className_3797c5 {font-family: '__PeydaFont_3797c5', '__PeydaFont_Fallback_3797c5'
}

@font-face {
font-family: '__AxiformaFont_124eeb';
src: url(/_next/static/media/7554ea643bf147df-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: '__AxiformaFont_124eeb';
src: url(/_next/static/media/867189eed67f65fa-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__AxiformaFont_124eeb';
src: url(/_next/static/media/99243ddb09b1fb0d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__AxiformaFont_124eeb';
src: url(/_next/static/media/da051398744e64e8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__AxiformaFont_124eeb';
src: url(/_next/static/media/6c939865e26f3ad8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__AxiformaFont_124eeb';
src: url(/_next/static/media/bac2073ddde225d0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__AxiformaFont_124eeb';
src: url(/_next/static/media/be011545846df5cf-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__AxiformaFont_124eeb';
src: url(/_next/static/media/9006da84b5705777-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__AxiformaFont_Fallback_124eeb';src: local("Arial");ascent-override: 68.67%;descent-override: 20.40%;line-gap-override: 52.01%;size-adjust: 112.28%
}.__className_124eeb {font-family: '__AxiformaFont_124eeb', '__AxiformaFont_Fallback_124eeb'
}

